import React from "react";
import { CHAT_PROMPT } from "../../../constant";
import { Card } from "react-bootstrap";

const ChatPrompts = ({ onCardClick, loading }) => {
  return (
    <div>
      <div
        style={{
          width: "214px",
          height: "257px",
          backgroundColor: "whitesmoke",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "7px",
          marginLeft: "20px",
          marginTop: "10px",
          marginBottom: "10px",
          pointerEvents: loading ? "none" : "auto", // Disable interactions when loading
          opacity: loading ? 0.5 : 1, // Dim the component when loading
        }}
      >
        <p className="fw-semibold m-0 p-0 mb-1 text-left">You can ask that.</p>
        {CHAT_PROMPT.map((card, index) => (
          <Card
            key={index}
            style={{
              width: "95%",
              display: "flex",
              cursor: loading ? "not-allowed" : "pointer", // Disable pointer when loading
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #F0F0F0",
              height: "auto",
              borderRadius: "8.5px",
              minHeight: "35px",
              gap: "0",
              backgroundColor: loading ? "#e0e0e0" : "#ffffff", // Optional: change background when loading
            }}
            onClick={() => !loading && onCardClick(card.key)} // Prevent clicks when loading
            className="p-2 text-center mb-2 reviseCard"
          >
            <div>{card.key}</div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ChatPrompts;
