import * as React from "react";
import { Button } from "@fluentui/react-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Divider } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import ArticleIcon from "@mui/icons-material/Article";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faICursor } from "@fortawesome/free-solid-svg-icons";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { List, ListItem, useListSelection } from "@fluentui/react-migration-v0-v9";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import AssistantMenu from "../MainScreen/AssistantMenu";
const items = [
  {
    content: "Comments and Redlines",
  },
  {
    content: "Comments Only",
  },
  {
    content: "Redlines Only",
  },
];
const list = [
  {
    content: "LAWGIC PRIVATE LIMITED",
  },
  {
    content: "[Individual's Full Legal Name]",
  },
  {
    content: "Max Ye",
  },
];
const ReviewScreen = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const SuggestionsScreen = () => {
    navigate("/reviewSuggestions");
  };
  const selection = useListSelection({ selectionMode: "single" });
  return (
    <>
      <Container>
        <Row>
          <AssistantMenu />
        </Row>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <Col className="mt-2">
            <span>
              <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
            </span>
          </Col>
          <Col>
            <div
              className="font-sans fw-semibold fs-6 text-muted"
              style={{
                marginTop: "12px",
              }}
            >
              Review
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-4 mx-1">
          <h6 className="pb-1">General Review</h6>
          <p className="pb-1">Scan for obvious risks and issues</p>
        </Row>

        <Row>
          <Col
            className="d-flex"
            style={{
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: "0px",
              paddingRight: "6px;",
            }}
          >
            <Button
              appearance="circular"
              style={{
                fontSize: "13px",
                paddingTop: "7px",
                paddingBottom: "7px",
              }}
              icon={<ArticleIcon sx={{ fontSize: 17 }} />}
            >
              Full Document
            </Button>

            <Button
              appearance="circular"
              style={{
                fontSize: "13px",
              }}
            >
              <FontAwesomeIcon
                icon={faICursor}
                size="sm"
                style={{
                  marginRight: "10px",
                  marginLeft: "6px",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              />
              Selection
            </Button>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "16px",
          }}
        >
          <Col xs={1}></Col>
          <Col xs={10} className="p-0">
            <Popover trapFocus size="large">
              <PopoverTrigger disab leButtonEnhancement>
                <Button
                  appearance="outline"
                  className="text-muted"
                  size="medium"
                  style={{
                    justifyContent: "flex-start",
                    borderRadius: "14px",
                    height: "47px",
                  }}
                >
                  Comments and Redlines
                  <ExpandMoreOutlined
                    style={{
                      marginLeft: "64px",
                    }}
                  />
                </Button>
              </PopoverTrigger>

              <PopoverSurface
                className="mt-3"
                style={{
                  width: "85%",
                  borderRadius: "20px",
                  fontSize: "19px",
                  height: "155px",
                  paddingTop: "2px",
                }}
              >
                <div>
                  <List
                    truncateContent
                    truncateHeader
                    selectable
                    defaultSelectedItems={[]}
                    selectedItems={selection.selectedItems}
                    onSelectionChange={(_, data) => selection.setSelectedItems(data.selectedItems)}
                  >
                    {items.map(({ key, content }) => (
                      <ListItem key={key} value={key} className="list_item">
                        {content}
                      </ListItem>
                    ))}
                  </List>
                </div>
              </PopoverSurface>
            </Popover>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "16px",
            height: "40px",
          }}
        >
          <Col xs={1}></Col>
          <Col xs={10} className="p-0">
            <Popover trapFocus size="large">
              <PopoverTrigger disab leButtonEnhancement>
                <Button
                  appearance="outline"
                  className="text-muted"
                  size="medium"
                  style={{
                    justifyContent: "flex-start",
                    borderRadius: "14px",
                    height: "47px",
                  }}
                >
                  <GroupsOutlinedIcon
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  Representing Max Ye
                  <ExpandMoreOutlined
                    style={{
                      marginLeft: "50px",
                    }}
                  />
                </Button>
              </PopoverTrigger>

              <PopoverSurface
                className="mt-3"
                style={{
                  width: "85%",
                  borderRadius: "20px",
                  fontSize: "19px",
                  height: "195px",
                  paddingTop: "2px",
                }}
              >
                <div>
                  <List
                    truncateContent
                    truncateHeader
                    selectable
                    defaultSelectedItems={[]}
                    selectedItems={selection.selectedItems}
                    onSelectionChange={(_, data) => selection.setSelectedItems(data.selectedItems)}
                  >
                    {list.map(({ key, content }) => (
                      <ListItem key={key} value={key} className="list_item">
                        {content}
                      </ListItem>
                    ))}
                    <Divider inset className="mb-2" />

                    <Button icon={<AddRegular />} appearance="transparent">
                      Add New Party
                    </Button>
                  </List>
                </div>
              </PopoverSurface>
            </Popover>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "30px",
          }}
        >
          <Col xs={1}></Col>
          <Col xs={10} className="p-0">
            <Button icon={<PlayCircleIcon />} className="review_btn" onClick={SuggestionsScreen}>
              Start Review
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReviewScreen;
