import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import { NavigationRegular } from "@fluentui/react-icons";
import { useId, Button, Popover, PopoverTrigger, PopoverSurface, Divider } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { List, ListItem, useListSelection } from "@fluentui/react-migration-v0-v9";
import AssistantMenu from "../MainScreen/AssistantMenu";

const items = [
  {
    content: "LAWGIC PRIVATE LIMITED",
  },
  {
    content: "[Individual's Full Legal Name]",
  },
  {
    content: "Max Ye",
  },
];
const NegotiateQuestion = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const NegotiateScreen = () => {
    navigate("/NegotiateScreen");
  };
  const selection = useListSelection({ selectionMode: "single" });
  const id = useId();
  return (
    <div>
      <Container>
        <Row>
          <AssistantMenu />
        </Row>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <Col className="mt-2">
            <span>
              <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
            </span>
          </Col>
          <Col>
            <div
              className="font-sans fw-semibold fs-6 text-muted"
              style={{
                marginTop: "12px",
              }}
            >
              Lawgic
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row
          style={{
            margin: "7px",
            marginTop: "14px",
          }}
        >
          <Popover trapFocus size="large">
            <PopoverTrigger disab leButtonEnhancement>
              <Button
                appearance="outline"
                className="text-muted"
                size="medium"
                style={{
                  justifyContent: "flex-start",
                  borderRadius: "14px",
                  height: "47px",
                }}
              >
                <GroupsOutlinedIcon
                  style={{
                    marginRight: "10px",
                  }}
                />
                LAWGIC PRIVATE LIMITED
                <ExpandMoreOutlinedIcon
                  style={{
                    marginLeft: "37px",
                  }}
                />
              </Button>
            </PopoverTrigger>

            <PopoverSurface
              aria-labelledby={id}
              className="mt-3"
              style={{
                width: "85%",
                borderRadius: "20px",
                fontSize: "19px",
                height: "195px",
                paddingTop: "2px",
              }}
            >
              <div>
                <List
                  truncateContent
                  truncateHeader
                  selectable
                  defaultSelectedItems={[]}
                  selectedItems={selection.selectedItems}
                  onSelectionChange={(_, data) => selection.setSelectedItems(data.selectedItems)}
                >
                  {items.map(({ key, content }) => (
                    <ListItem key={key} value={key} className="list_item">
                      {content}
                    </ListItem>
                  ))}
                  <Divider inset className="mb-2" />

                  <Button icon={<AddRegular />} appearance="transparent">
                    Add New Party
                  </Button>
                </List>
              </div>
            </PopoverSurface>
          </Popover>
        </Row>
        <Row
          style={{
            marginTop: "20px",
          }}
        >
          <Col className="p-0">
            <Button className="lawgic_btn" onClick={NegotiateScreen}>
              Cast Points To Negotiate{" "}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NegotiateQuestion;
