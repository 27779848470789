import * as React from "react";
import PropTypes from "prop-types";
// import Header from "./Header";
// import HeroList from "./HeroList";
// import TextInsertion from "./TextInsertion";
import ReviewSuggestions from "./GetStarted/Review/ReviewSuggestions";
import FrontPage from "./GetStarted/MainScreen/FrontPage";
import Index from "./GetStarted/Index";
import AQ from "./GetStarted/Ask/AQ";
import { makeStyles } from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./GetStarted/MainScreen/HomeScreen";
import ListInfo from "./GetStarted/ListItems/ListInfo";
import AskScreen from "./GetStarted/Ask/AskScreen";
import ReviewScreen from "./GetStarted/Review/ReviewScreen";
import ReviewAccordion from "./GetStarted/Review/ReviewAccordion";
import DraftQuestion from "./GetStarted/Draft/DraftQuestion";
import Draft from "./GetStarted/Draft/Draft";
import Login from "./Login";

import NegotiateQuestion from "./GetStarted/Negotiate/NegotiateQuestion";
import NegotiateScreen from "./GetStarted/Negotiate/NegotiateScreen";
import AskChat from "./GetStarted/Ask/chat/AskChat";
import Languages from "./GetStarted/Translate/Languages";
import ReviseClause from "./GetStarted/Revise/ReviseClause";
import ReviseScreen from "./GetStarted/Revise/ReviseScreen";
import "../../../assets/Main.css";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = () => {
  const styles = useStyles();
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.

  return (
    <div className={styles.root}>
      <Router>
        <Routes>
          <Route exact path="/Index" element={<Login />} />

          <Route exact path="/" element={<Index />} />

          <Route path="/frontpage" element={<FrontPage />} />
          <Route path="/AQ" element={<AQ />} />

          <Route path="/AskScreen" element={<AskScreen />} />

          <Route path="/reviewScreen" element={<ReviewScreen />} />
          <Route path="/reviewSuggestions" element={<ReviewSuggestions />} />
          <Route path="/DraftQuestion" element={<DraftQuestion />} />

          <Route path="/Draft" element={<Draft />} />

          <Route path="/ReviseClause" element={<ReviseClause />} />

          <Route path="/ReviseScreen" element={<ReviseScreen />} />

          <Route path="/NegotiateQuestion" element={<NegotiateQuestion />} />

          <Route path="/NegotiateScreen" element={<NegotiateScreen />} />
          <Route path="/Languages" element={<Languages />} />
          <Route path="/Chat" element={<AskChat />} />
        </Routes>
      </Router>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
