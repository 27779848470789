import React from "react";

const Loader = () => {
  return (
    <div>
      <img width={32} height={32} src="https://www.lawgic.sg/review/assets/Loader-CZbZ12IH.gif" alt="loading..."></img>
    </div>
  );
};

export default Loader;
