import React from "react";
import Card from "react-bootstrap/Card";

const Cards = () => {
  return (
    <>
      <Card style={{ width: "18rem", margin: "18px"}} className="card_focus">
        <Card.Body>
          <Card.Subtitle
            className="mb-2 text-muted "
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "large",
            }}
          >
            <span>
              <img src="assets/card_frame.png" alt="" width={29} height={29} className="me-2" />
            </span>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "7px",
              }}
              className="card_lawgic"
            >
              Lawgic
            </span>
          </Card.Subtitle>
          <Card.Title>Write a Review Email</Card.Title>
          <Card.Text className="text-muted fs-6">
            Subject: Review of Confidentiality Agreement Dear Client, The document you provided for review is a
            confidentiality agreement between LAWGlC and another company. The
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Cards;
