import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@fluentui/react-components";
import List from "./List";
import { SearchRegular } from "@fluentui/react-icons";
import { NavigationRegular, DismissRegular } from "@fluentui/react-icons";
import Container from "react-bootstrap/Container";
import { Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-components";
import { makeStyles, Input, Divider } from "@fluentui/react-components";
import Chip from "@mui/material/Chip";
const useStyles = makeStyles({
  root: {
    maxWidth: "200px",
    marginTop: "8px",
  },
});

const ListInfo = () => {
  const [list, setList] = useState(true);
  const styles = useStyles();
  const handleAssistant = () => {
    setList(true);
  };

  return (
    <>
      <Row>
        <Container>
          <Row
            style={{
              borderBottom: "1px solid black",
              paddingBottom: "5px",
              borderColor: "#CCCCCC",
            }}
          >
            <Col className="mt-2" xs={2}>
              <span>
                <Button
                  size="medium"
                  appearance="transparent"
                  icon={<DismissRegular />}
                  onClick={() => setList(false)}
                />
              </span>
            </Col>
            <Col>
              <div className={styles.root}>
                <Input
                  variant="underlined"
                  contentBefore={<SearchRegular />}
                  placeholder="Search for Lawgic"
                  style={{
                    borderRadius: "20px",
                    border: "1px solid #dfdfdf",
                    backgroundColor: "#f6f6f6",
                  }}
                />
              </div>
            </Col>
          </Row>
          <Col>
            <div>
              <List />
              <Divider />

              <Button
                appearance="transparent"
                style={{
                  float: "right",
                  marginTop: "7px",
                  marginBottom: "7px",
                }}
                onClick={() => setList(false)}
              >
                <Chip
                  label="Esc"
                  size="small"
                  style={{
                    marginRight: "7px",
                    borderRadius: "6px",
                  }}
                />
                Close
              </Button>
            </div>
          </Col>
        </Container>

        <Col></Col>
      </Row>
    </>
  );
};

export default ListInfo;
