import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@fluentui/react-components";
import { REVISE_CLAUSE_PROMPT } from "../../constant";
import CardComponent from "../Card";
import { PopoverSurface as RevisePopoverSurface } from "@fluentui/react-components";
import "./index.css";

const ReviseClause = () => {
  const [textFieldValue, setTextFieldValue] = useState("");
  const navigate = useNavigate();

  const homePage = () => {
    navigate("/frontpage");
  };

  const ReviseScreen = () => {
    navigate("/ReviseScreen", { state: { content: textFieldValue, direction: "revise" } });
  };

  const handleCardClick = (value) => {
    setTextFieldValue(value);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  // Determine if the button should be disabled
  const isButtonDisabled = textFieldValue.trim() === "";

  // Set button class based on whether it is disabled
  const buttonClass = isButtonDisabled ? "" : "gradient_color";

  return (
    <div>
      <Container>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <RevisePopoverSurface
            tabIndex={-1}
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <Row
              style={{
                borderBottom: "1px solid black",
                paddingBottom: "5px",
                borderColor: "#CCCCCC",
              }}
            >
              <Col className="mt-2">
                <span>
                  <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
                </span>
              </Col>
              <Col>
                <div
                  className="font-sans fw-semibold fs-6 text-muted"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Instruction
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Box component="form" noValidate autoComplete="off">
                <textarea
                  className="reviseTextarea"
                  id="standard-multiline-static"
                  rows={6}
                  placeholder="Be specific and verbose. The more detail you provide, the better the results.

1. Select the text on the left.
2. Enter instruction in the dialogue box.
3. Click Revise for revision."
                  style={{
                    marginTop: "9px",
                    borderRadius: "6px",
                    width: "100%",
                    borderColor: "#80808038",
                  }}
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                />
              </Box>
              <Row className="d-flex justify-content-center flex-row">
                <p className="mt-3 fs-6 fw-medium">Try Asking</p>
                <Col>
                  {REVISE_CLAUSE_PROMPT.map((card, index) => (
                    <CardComponent key={index} card={card} handleCardClick={handleCardClick} />
                  ))}
                </Col>
              </Row>
              <Col xs={10}></Col>
              <Col className="d-flex justify-content-end">
                <Button
                  shape="rounded"
                  size="medium"
                  className={`mt-3 ${buttonClass}`} // Apply class based on condition
                  disabled={isButtonDisabled} // Disable button based on condition
                  onClick={ReviseScreen}
                >
                  Revise
                </Button>
              </Col>
            </Row>
          </RevisePopoverSurface>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReviseClause;
