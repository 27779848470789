export const REVISE_CLAUSE_PROMPT = [
  {
    key: "Revise based on feedback:",
    value: "Revise based on feedback:",
    content: "[insert feedback]",
  },
  {
    key: "Add contingencies to address potential:",
    value: "Add contingencies to address potential:",
    content: "[issues or situations]",
  },
  {
    key: "Address risks and liabilities for",
    value: "Address risks and liabilities for",
    content: "[party name]",
  },
  {
    key: "Ensure responsibilities of are clear",
    value: "Ensure responsibilities of are clear",
    content: "[party name]",
  },
  {
    key: "  Make this clause concise and clear",
    value: "  Make this clause concise and clear",
  },
];

export const DRAFT_CLAUSE_PROMPT = [
  {
    key: "Draft a confidentiality clause to keep private:",
    value: "Draft a confidentiality clause to keep private:",
    content: "[sensitive information]",
  },
  {
    key: "Draft a termination clause that specifies:",
    value: "Draft a termination clause that specifies:",
    content: " [conditions]",
  },
  {
    key: "Draft an indemnity clause that protects from legal liability",
    value: "Draft an indemnity clause that protects from legal liability",
    content: "[party name]",
  },
  {
    key: "Draft a dispute resolution clause that outlines the process for resolving conflicts",
    value: "Draft a dispute resolution clause that outlines the process for resolving conflicts",
  },
];

export const CHAT_PROMPT = [
  {
    key: "Explain like I'm new",
    value: "Explain like I'm new",
  },
  {
    key: "Write a Review Email",
    value: "Write a Review Email",
  },
  {
    key: "Write an executive summary",
    value: "Write an executive summary",
  },
  {
    key: "Review for Fairness",
    value: "Review for Fairness",
  },
  {
    key: "Explain My Rights",
    value: "Explain My Rights",
  },
];
