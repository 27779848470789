import * as React from "react";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@fluentui/react-components";
import { Switch } from "@fluentui/react-components";
import Container from "react-bootstrap/Container";
import { Spinner } from "@fluentui/react-components";
import { CheckmarkRegular } from "@fluentui/react-icons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Field, ProgressBar } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import ReviewCard from "./ReviewCard";
import ReviewAccordion from "./ReviewAccordion";

const ReviewSuggestions = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const [cardVisibility, setCardVisibility] = useState(true);
  const [checked, setChecked] = useState(true);
  const toggleCards = () => {
    setCardVisibility(!cardVisibility);
    setChecked(!checked);
  };

  return (
    <div>
      <Container>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <Col xs={3} className="mt-2">
            <span>
              <HomeOutlinedIcon
                onClick={homePage}
                style={{
                  marginRight: "9px",
                  cursor: "pointer",
                  opacity: ".5",
                }}
              />
            </span>

            <span>
              <ControlPointIcon
                style={{
                  width: "20px",
                  cursor: "pointer",
                  opacity: ".5",
                }}
              />
            </span>
          </Col>

          <Col xs={5} className="d-flex justify-content-center">
            <div
              className="font-sans fw-semibold fs-6 text-muted"
              style={{
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Review
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-4 mx-1">
          <h6 className="pb-1">General Review</h6>
          <p className="pb-1">Scan for obvious risks and issues</p>
        </Row>

        <Row className="ps-4 pt-2  w-100">
          <Col xs={1} className="p-0 pt-1">
            <span>
              <Spinner size="extra-tiny" />
            </span>
          </Col>
          <Col xs={8} className="p-0">
            <span
              style={{
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              5 Suggestions, still working...
            </span>
          </Col>
          <Col xs={2} className="p-0">
            <Button className="reviewSuggesion-btn" size="small">
              Cancel
            </Button>
          </Col>
        </Row>
        {cardVisibility && (
          <>
            <Row>
              <Field validationState="none">
                <ProgressBar
                  style={{
                    marginTop: "20px",
                    height: "8px",
                  }}
                  shape="rounded"
                  thickness="large"
                  value={0.6}
                />
              </Field>
            </Row>
            {/* <ReviewCard />
            <ReviewCard /> */}
            <ReviewAccordion />
          </>
        )}
        <Row>
          <div className="review_footer">
            <Col xs={9} className="p-0">
              <Switch
                onClick={toggleCards}
                label="Show actioned Items"
                checked={checked}
                style={{
                  fontSize: "13px",
                }}
              />
            </Col>
            <Col xs={3} className="p-0">
              <Button className="footer_btn">
                <CheckmarkRegular className="me-2" /> Apply All
              </Button>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewSuggestions;
