import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import { makeStyles, shorthands, Caption1, Text, tokens } from "@fluentui/react-components";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

const useStyles = makeStyles({
  root: {
    maxWidth: "200px",
    marginTop: "6px",
  },
  card: {
    marginLeft: "8px",
    width: "95%",
    height: "fit-content",
    cursor: "grab",
    marginBottom: "5px",

    ":hover": {
      backgroundColor: "#E9F0FE",
    },
  },
  horizontalCardImage: {
    width: "29px",
    height: "29px",
    opacity: ".5",
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: {
    ...shorthands.margin(0),
  },
});
const List = () => {
  const navigate = useNavigate();
  const Ask = () => {
    navigate("/AQ");
  };
  const Review = () => {
    navigate("/reviewScreen");
  };
  const Draft = () => {
    navigate("/DraftQuestion");
  };
  const Revise = () => {
    navigate("/ReviseClause");
  };
  const Negotiate = () => {
    navigate("/NegotiateQuestion");
  };

  const Translate = () => {
    navigate("/Languages");
  };

  const styles = useStyles();
  return (
    <div>
      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          marginLeft: "-3px",
        }}
      >
        <Card
          onClick={Ask}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <ChatOutlinedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>
          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Ask a question</Text>}
            description={<Caption1 className={styles.caption}>Discuss the document, extract info</Caption1>}
          />
        </Card>
      </Row>

      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          marginLeft: "-3px",
        }}
      >
        <Card
          // onClick={Review}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <NoteAltOutlinedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>
          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Review the document</Text>}
            description={<Caption1 className={styles.caption}>Suggest improvements, add redlines</Caption1>}
          />
        </Card>
      </Row>

      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          marginLeft: "-3px",
        }}
      >
        <Card
          onClick={Draft}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <BorderColorOutlinedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>
          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Draft a clause</Text>}
            description={<Caption1 className={styles.caption}>Generate with Al or search your library</Caption1>}
          />
        </Card>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          marginLeft: "-3px",
        }}
      >
        <Card
          onClick={Revise}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <EditNoteRoundedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>

          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Revise a clause</Text>}
            description={<Caption1 className={styles.caption}>Rewrite clause, with your instruction</Caption1>}
          />
        </Card>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          marginLeft: "-3px",
        }}
      >
        <Card
          // onClick={Negotiate}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <HandshakeOutlinedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>
          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Negotiate a Clause</Text>}
            description={<Caption1 className={styles.caption}>Suggest pointers, in your position</Caption1>}
          />
        </Card>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "3px",
          cursor: "not-allowed",
          marginLeft: "-3px",
        }}
      >
        <Card
          // onClick={Translate}
          className={styles.card}
          orientation="horizontal"
          appearance="subtle"
          style={{
            borderRadius: "15px",
            border: "1px solid #cccccc5e",
          }}
        >
          <CardPreview className={styles.horizontalCardImage}>
            <LanguageOutlinedIcon
              style={{
                marginLeft: "10px",
              }}
            />
          </CardPreview>

          <CardHeader
            className="ms-2"
            header={<Text weight="semibold">Translate</Text>}
            description={<Caption1 className={styles.caption}>One click translation, multi-language</Caption1>}
          />
        </Card>
      </Row>
    </div>
  );
};

export default List;
