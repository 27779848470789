import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { PopoverSurface } from "@fluentui/react-components";
import ChatPrompts from "./chat/chatPrompts";

const AQ = () => {
  const [inputValue, setInputValue] = useState("");

  const getInputValue = (e) => {
    let value = e.target.value;
    setInputValue(value);
    console.log(value);
  };

  const handleCardClick = (prompt) => {
    setInputValue(prompt);
  };

  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const AskScreenPage = () => {
    navigate("/Chat", { state: { Text: inputValue } });
  };

  return (
    <div>
      <Container>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <PopoverSurface
            tabIndex={-1}
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <Row
              style={{
                borderBottom: "1px solid black",
                paddingBottom: "5px",
                borderColor: "#CCCCCC",
              }}
            >
              <Col className="mt-2">
                <span>
                  <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
                </span>
              </Col>
              <Col>
                <div
                  className="font-sans fw-semibold fs-6 text-muted"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Ask
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Box component="form" noValidate autoComplete="off">
                <TextField
                  id="standard-multiline-static"
                  multiline
                  rows={3}
                  value={inputValue}
                  onChange={getInputValue}
                  variant="standard"
                  placeholder="Ask a question..."
                  style={{
                    width: "250px",
                    marginTop: "9px",
                  }}
                />
              </Box>
              <Row>
                <ChatPrompts onCardClick={handleCardClick} />
              </Row>
              <Col xs={10}></Col>
              <Col className="d-flex justify-content-end">
                <Button shape="rounded" size="small" onClick={AskScreenPage}>
                  Ask
                </Button>
              </Col>
            </Row>
          </PopoverSurface>

          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default AQ;
