import React, { useState, useEffect } from "react";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from "react-bootstrap";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { sendRequest } from "../../Services";
import AssistantMenu from "../MainScreen/AssistantMenu";
import { Tooltip } from "@mui/material";
import Loader from "../Loader";
import { replaceSelectedTextWithHTML } from "../../../../office-document";
const ReviseScreen = () => {
  const navigate = useNavigate();
  const [responseLoading, setResponseLoading] = useState(false);
  const [responseContent, setResponseContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();

  const { content, direction } = location.state;
  useEffect(() => {
    setResponseLoading(true);
    sendRequest(content, direction)
      .then((result) => {
        if (result.code === "200") {
          setResponseContent(result.data.content);
        } else {
          setResponseContent("");
        }
        setResponseLoading(false);
        console.log(result);
      })
      .catch((error) => {
        console.error(error);

        setResponseLoading(false);
      });
  }, []);
  const homePage = () => {
    navigate("/frontpage");
  };

  const totalPages = 4; // Replace with the actual number of pages

  const handleNextPage = () => {
    setCurrentPage(currentPage === totalPages ? 1 : currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage === 1 ? totalPages : currentPage - 1);
  };

  const replaceContant = () => {
    replaceSelectedTextWithHTML(responseContent);
  };

  const generatePageContent = (page) => {
    // You can replace this logic with your dynamic content generation for the <p> tag
    switch (page) {
      case 1:
        return responseContent;
      case 2:
        return "Content for Page 2: Updated content for Page 2";
      case 3:
        return "Content for Page 3: Updated content for Page 3";
      case 4:
        return "Content for Page 4: Updated content for Page 4";
      default:
        return "";
    }
  };

  const pageContent = generatePageContent(currentPage);

  return (
    <>
      <Container>
        <AssistantMenu />

        <div className="row">
          <div className="col-2 mt-2">
            <Tooltip placement="top" title="Home">
              <HomeOutlinedIcon
                sx={{ fontSize: 27 }}
                style={{ color: "black", opacity: 0.4, cursor: "pointer" }}
                onClick={homePage}
              />
            </Tooltip>
          </div>
          <div className="col-2 mt-2">
            <AddCircleOutlineOutlinedIcon
              sx={{ fontSize: 22 }}
              style={{ marginBottom: "3px", color: "black", opacity: 0.4, cursor: "pointer" }}
              className="plus_icon"
            />
          </div>
          <div className="col-1"></div>
          <div className="col-2 mt-2">
            <p className="mt-1" style={{ fontWeight: 600, fontSize: "16px", opacity: 0.5 }}>
              Revise
            </p>
          </div>
          <div className="col-3"></div>
          <div className="col-2"></div>
        </div>
        <div className="row">
          <div className="icons"></div>
        </div>

        <div className="wrapper">
          <div
            className="wrapper_upper"
            style={{
              borderBottom: "1px solid grey",
              backgroundColor: "#cccccc47",
              width: "108%",
              position: "relative",
              right: "12px",
            }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              style={{
                marginLeft: "8px",
                marginRight: "0px",
                paddingRight: "0px",
                width: "90%",
              }}
            >
              <TextField
                id="standard-multiline-static"
                multiline
                rows={3}
                variant="standard"
                value={content}
                placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                style={{
                  width: "270px",
                }}
              />
            </Box>
            <div className="wrapper_buttons">
              <Button href="#" variant="outline-secondary">
                Clear
              </Button>
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} id="UpRightAndDownLeftArrow" />
            </div>
          </div>
          {/* 
          <div className="pagination">
            <p>
              {currentPage} of {totalPages}
            </p>
            <Button variant="secondary" onClick={handlePreviousPage} disabled={currentPage === 1}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  paddingBottom: "4px",
                }}
              />
            </Button>
            <Button variant="secondary" onClick={handleNextPage} disabled={currentPage === totalPages}>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  paddingBottom: "4px",
                }}
              />
            </Button>
          </div> */}
          {responseLoading ? (
            <div>
              <div className="gradient_color" style={{ height: "7px", width: "100%", marginTop: "6px" }}></div>
              <div>
                <p className="fw-semibold fs-6 mt-2">Loading</p>
              </div>
              <div className="d-flex">
                <Loader />
                <span className="fw-semibold mt-1">Still working</span>
              </div>
            </div>
          ) : (
            <div className="wrapper_hero">
              <Card style={{ width: "95%" }} className="hero_card">
                <p
                  key={currentPage}
                  className="text-muted fw-normal"
                  style={{
                    maxHeight: "500px",
                    height: "265px",
                    overflow: "auto",
                  }}
                >
                  {pageContent}
                </p>

                <div className="wrapper_hero_buttons">
                  <div className="trash">
                    <Button
                      variant="outline-light text-muted"
                      style={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{
                          paddingBottom: "5px",
                        }}
                      />
                    </Button>
                  </div>
                  <div className="copy">
                    <Button className="review_card_btn" onClick={replaceContant}>
                      Confirmed
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ReviseScreen;
