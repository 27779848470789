import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
const ReviewAccordion = () => {
  return (
    <>
      <div
      // style={{
      //   maxhHeight: "1000px",
      //   height: "400px",
      //   overflow: "auto",
      // }}
      >
        <Accordion>
          <Accordion.Item
            eventKey="0"
            style={{
              border: "none",
              marginBottom: "11px",
              marginTop: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Typographical Correction
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="1"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Typographical Correction
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="2"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Clarification on Commercial Advantage
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="3"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Clarification on Liability
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="4"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Understanding of legal implications
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="5"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Typographical Correction
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="6"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Typographical Correction
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="7"
            style={{
              border: "none",
              marginBottom: "11px",
            }}
          >
            <Accordion.Header>
              <CheckCircleOutlineOutlinedIcon
                style={{
                  color: "#005FF9",
                  height: "20px",
                  marginRight: "7px",
                }}
              />{" "}
              Typographical Correction
            </Accordion.Header>
            <Accordion.Body>
              Either party may terminate this Agreement for any reason upon prior written notice to the other party.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default ReviewAccordion;
