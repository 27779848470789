import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import "bootstrap/dist/css/bootstrap.min.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssistantMenu from "../MainScreen/AssistantMenu";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const AskScreen = () => {
  const navigate = useNavigate();

  const homePage = () => {
    navigate("/frontpage");
  };
  const input_style = {
    padding: "3px",
    width: "90%",
    border: "none",
    outline: "none",
  };
  const search_box = {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "13px",
    padding: "5px",
    width: "95%",
    marginLeft: "6px",
    height: "47px",
    position: "relative",
    bottom: "8px",
  };
  return (
    <div>
      <Container>
        <AssistantMenu />
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <Col xs={3} className="mt-2">
            <span>
              <Tooltip title="Home" placement="top">
                <HomeOutlinedIcon
                  onClick={homePage}
                  style={{
                    marginRight: "9px",
                    cursor: "pointer",
                    opacity: ".5",
                  }}
                />
              </Tooltip>
            </span>

            <span>
              <ControlPointIcon
                style={{
                  width: "20px",
                  cursor: "pointer",
                  opacity: ".5",
                }}
              />
            </span>
          </Col>

          <Col xs={5} className="d-flex justify-content-center">
            <div
              className="font-sans fw-semibold fs-6 text-muted"
              style={{
                marginTop: "10px",
                marginBottom: "8px",
              }}
            >
              Ask
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <div className="main p-3">
            <h5>
              What are the important norms <br /> of confidentiality agreements
            </h5>
            <div
              className="chat"
              style={{
                backgroundColor: "#F6F6F6",
                width: "92%",
                height: "auto",

                borderRadius: "14px",
                overflow: "auto",
              }}
            >
              <p className="p-2">
                to disclose confidential information. These exceptions may include when the receiving party is under a
                court order or when the information has already been made public.
                <br />
                4. The duration of the confidentiality period. The agreement should specify the duration of the bailout
                period, i.e., the length of time for which the receiving party must maintain the confidentiality of the
                information.
                <br />
                5. Liability and Remedies:The agreement should clearly outline the potential liability and remedies if
                the release is breached.
                <br />
                6. Ownership of Information:The agreement should clearly set out the ownership of the confidential
              </p>
            </div>
          </div>
        </Row>
        <Row>
          <Col className="text-muted">
            <span>
              <ContentCopyIcon
                style={{
                  width: "20px",
                  marginRight: "8px",
                  marginLeft: "9px",
                }}
              />
            </span>
            <span>
              <FileUploadOutlinedIcon />
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              marginTop: "15px",
            }}
          >
            <div style={search_box}>
              <input type="text" placeholder="Ask a question..." style={input_style} />
              <div className="input-group-append">
                <FontAwesomeIcon icon={faCircleUp} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AskScreen;
