import { marked } from "marked";

// wordHelper.js

export async function convertTexttoHTML() {
  return new Promise((resolve, reject) => {
    Word.run(async (context) => {
      try {
        // Get the current selection
        const selection = context.document.getSelection();

        // Load the selected text
        selection.load("text");

        // Synchronize the context to load the text
        await context.sync();

        // Convert the selected text to HTML
        // const htmlContent = `<p>${selection.text.replace(/\n/g, "<br>")}</p>`;

        // Resolve the promise with the HTML content

        resolve(selection.text);
      } catch (error) {
        // Reject the promise if there's an error
        reject(error);
      }
    }).catch((error) => {
      // Handle any errors that might occur in the Word.run context
      reject(error);
    });
  });
}

export async function getDocumentContent() {
  return new Promise((resolve, reject) => {
    Word.run(async (context) => {
      try {
        // Get the current selection
        const selection = context.document.body;
        var range = selection.getRange("Whole");

        // Load the selected text
        range.load("text");

        // Synchronize the context to load the text
        await context.sync();

        // Resolve the promise with the HTML content
        resolve(range.text);
      } catch (error) {
        // Reject the promise if there's an error
        reject(error);
      }
    }).catch((error) => {
      // Handle any errors that might occur in the Word.run context
      reject(error);
    });
  });
}

// Function to replace the selected text with HTML
export async function replaceSelectedTextWithHTML(htmlContent) {
  let parsedContent = marked.parse(htmlContent).toString();
  return Word.run(async (context) => {
    const selection = context.document.getSelection();
    selection.insertHtml(parsedContent, Word.InsertLocation.replace);
    await context.sync();
  }).catch((error) => {
    console.error("Error replacing selected text with HTML:", error);
    throw error;
  });
}

// Function to insert text at the current cursor position
export async function insertTextAtCursor(text) {
  let parsedContent = marked.parse(text).toString();

  return Word.run(async (context) => {
    const range = context.document.getSelection();
    range.insertHtml(parsedContent, Word.InsertLocation.replace);
    await context.sync();
  }).catch((error) => {
    console.error("Error inserting text at cursor:", error);
    throw error;
  });
}
// export async function getDocumentContent() {
//   return new Promise((resolve, reject) => {
//     Word.run(async (context) => {
//       try {
//         // Get the current selection
//         const selection = context.document.body;
//         var range = selection.getRange("Whole");

//         // Load the selected text
//         let html = range.getHtml("");

//         // Synchronize the context to load the text
//         await context.sync();

//         // Resolve the promise with the HTML content
//         resolve(html);
//       } catch (error) {
//         // Reject the promise if there's an error
//         reject(error);
//       }
//     }).catch((error) => {
//       // Handle any errors that might occur in the Word.run context
//       reject(error);
//     });
//   });
// }
