import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

import ArticleIcon from "@mui/icons-material/Article";
import { useId, Button, Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { List, ListItem, useListSelection } from "@fluentui/react-migration-v0-v9";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import AssistantMenu from "../MainScreen/AssistantMenu";

const items = [
  {
    content: "Chinese",
  },
  {
    content: "Spanish",
  },
  {
    content: "Arabic",
  },
  {
    content: "French",
  },
  {
    content: "Indonesian",
  },
  {
    content: "Portuguese",
  },
  {
    content: "Japanese",
  },
  {
    content: "Russian",
  },
  {
    content: "German",
  },
];
const Languages = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const selection = useListSelection({ selectionMode: "single" });

  const id = useId();
  return (
    <div>
      <Container>
        <AssistantMenu />
        <PopoverSurface
          tabIndex={-1}
          style={{
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            width: "100%",
          }}
        >
          <Row
            style={{
              borderBottom: "1px solid black",
              paddingBottom: "5px",
              borderColor: "#CCCCCC",
            }}
          >
            <Col>
              <span>
                <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
              </span>
            </Col>
            <Col>
              <div
                className="font-sans fw-semibold fs-6 text-muted"
                style={{
                  marginTop: "4px",
                }}
              >
                Translate
              </div>
            </Col>
            <Col></Col>
          </Row>
          <Row className="w-100 p-2">
            <Popover trapFocus size="large">
              <PopoverTrigger disab leButtonEnhancement>
                <Button
                  appearance="outline"
                  className="text-muted"
                  size="medium"
                  style={{
                    justifyContent: "flex-start",
                    borderRadius: "14px",
                    marginLeft: "12px",
                    height: "46px",
                  }}
                >
                  <LanguageIcon
                    style={{
                      marginRight: "10px",
                      opacity: ".7",
                    }}
                  />
                  <span className="text-muted">Chinese</span>

                  <ExpandMoreOutlinedIcon
                    style={{
                      marginLeft: "148px",
                      opacity: ".6",
                    }}
                  />
                </Button>
              </PopoverTrigger>

              <PopoverSurface
                aria-labelledby={id}
                className="mt-3"
                style={{
                  width: "85%",
                  borderRadius: "20px",
                  fontSize: "19px",

                  paddingTop: "2px",
                }}
              >
                <div>
                  <List
                    truncateContent
                    truncateHeader
                    selectable
                    defaultSelectedItems={[]}
                    selectedItems={selection.selectedItems}
                    onSelectionChange={(_, data) => selection.setSelectedItems(data.selectedItems)}
                  >
                    {items.map(({ key, content }) => (
                      <ListItem key={key} value={key} className="list_item">
                        {content}
                      </ListItem>
                    ))}
                  </List>
                </div>
              </PopoverSurface>
            </Popover>
          </Row>
          <Row
            style={{
              marginTop: "10px",
            }}
          >
            <Col className="p-0">
              <Button className="translate_btn">Translate</Button>
            </Col>
          </Row>

          <Row>
            <div className="recent">
              <Col xs={9}>
                <ArticleIcon
                  style={{
                    opacity: ".6",
                    marginRight: "4px",
                  }}
                />
                <p
                  style={{
                    display: "inline",
                    position: "relative",
                    top: "-6px",
                    fontWeight: "500",
                    opacity: ".5",
                  }}
                >
                  English
                </p>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  style={{
                    float: "right",
                    marginTop: "2px",
                    marginRight: "-54px",
                    opacity: "0.5",
                    width: "18px",
                    height: "22px",
                  }}
                />
              </Col>
            </div>
          </Row>
        </PopoverSurface>
      </Container>
    </div>
  );
};

export default Languages;
