import React, { useState } from "react";
import "../../../../../assets/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import ListInfo from "../ListItems/ListInfo";
import AssistantMenu from "./AssistantMenu";
import "../../../../../assets/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { DismissRegular } from "@fluentui/react-icons";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Cards from "../CardList/Cards";
import { Checkbox } from "@fluentui/react-components";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  Input,
  Label,
  makeStyles,
  MessageBar,
  MessageBarBody,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    color: "#999999",
  },
});

const FrontPage = () => {
  const [isNotify, setIsNotify] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const openPopup = () => {
    setPopUp(true);
  };

  const openPopupAssitant = (value) => {
    setPopUp(value);
  };

  const styles = useStyles();
  const handleInviteClick = () => {
    setPopUp(false);
    setIsNotify(true);
    // Set a timeout to change isNotify to false after one second
    setTimeout(() => {
      setIsNotify(false);
    }, 1000);
  };

  return (
    <>
      <Container>
        <AssistantMenu openPopupAssitant={openPopupAssitant} />

        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            borderColor: "#CCCCCC",
            height: "46px",
          }}
        >
          <Col
            className="mt-1"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src="assets/icon-32.png" alt="" className="m-2" width={21} height={19} />
            <span
              className="text-primary ms-1"
              style={{
                fontSize: "15px",
              }}
            >
              {" "}
              Select a magic to get started
            </span>
          </Col>
          <Popover>
            <PopoverTrigger disableButtonEnhancement>
              <Col
                xs={2}
                style={{
                  marginTop: "10px",
                }}
              >
                <Button
                  appearance="transparent"
                  size="small"
                  style={{
                    marginLeft: "-24px",
                  }}
                >
                  <UnfoldMoreIcon />
                </Button>
              </Col>
            </PopoverTrigger>

            <PopoverSurface
              tabIndex={-1}
              style={{
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                paddingBottom: "0px",
                paddingTop: "2px",
              }}
            >
              <ListInfo />
            </PopoverSurface>
          </Popover>
        </Row>
      </Container>

      {/* 2.2 and 2.3 */}
      <div>
        <Cards />

        {isNotify && (
          <div
            className="d-flex "
            style={{
              position: "fixed",
              zIndex: "23",
              width: "100%",
              top: "301px",
              left: "45px",
            }}
          >
            <MessageBar intent="success">
              <MessageBarBody>Thank you! Invitation sent.</MessageBarBody>
            </MessageBar>
          </div>
        )}

        <Cards />
        <Cards />
        <Button
          size="medium"
          shape="circular"
          className="btnnn w-75 d-flex justify-content-between"
          onClick={openPopup}
        >
          <span>
            <PersonAddAltIcon
              style={{
                width: "18px",
                marginTop: "3px",
                marginLeft: "4px",
              }}
            />
          </span>
          <span
            style={{
              fontWeight: "300",
              fontSize: "14px",
            }}
          >
            Invite someone to Lawgic
          </span>
          <a
            style={{
              color: "white",
              marginTop: "8px",
            }}
          >
            {<DismissRegular fontSize={16} />}
          </a>
        </Button>
        {popUp && (
          <DialogSurface aria-describedby={undefined}>
            <form>
              <DialogBody>
                <DialogTitle className="text-muted fs-6">Invite a colleague or friend to try Lawgic</DialogTitle>
                <DialogContent className={styles.content}>
                  They will skip to the front of our 83000+ person waitlist.
                  <InputGroup
                    style={{
                      width: "96%",
                      margin: "2px",
                    }}
                  >
                    <Form.Control
                      placeholder="Their Name"
                      type="text"
                      aria-describedby="inputGroup-sizing-sm"
                      style={{
                        padding: "9px",
                      }}
                    />
                  </InputGroup>
                  <InputGroup
                    style={{
                      width: "96%",
                      margin: "2px",
                    }}
                  >
                    <Form.Control
                      placeholder="Their Email"
                      type="email"
                      aria-describedby="inputGroup-sizing-sm"
                      style={{
                        padding: "9px",
                      }}
                    />
                  </InputGroup>
                </DialogContent>
              </DialogBody>
            </form>
            <Row>
              <Col xs={9}>
                <Checkbox
                  labelPosition="before"
                  label="Same Organization?"
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                  }}
                />
              </Col>

              <Col xs={2}>
                <Button
                  onClick={handleInviteClick}
                  shape="circular"
                  size="small"
                  style={{
                    marginLeft: "-23px",
                    marginTop: "5px",
                  }}
                >
                  Invite
                </Button>
              </Col>
            </Row>
          </DialogSurface>
        )}
        <Dialog modalType="non-modal">
          <DialogTrigger disableButtonEnhancement></DialogTrigger>
          <DialogSurface aria-describedby={undefined}>
            <form>
              <DialogBody>
                <DialogTitle className="text-muted">Invite a colleague or friend to try Lawgic</DialogTitle>
                <DialogContent className={styles.content}>
                  They will skip to the front of our 83000+ person waitlist.
                  <Label required htmlFor={"email-input"}>
                    Email input
                  </Label>
                  <Input required type="email" id={"email-input"} />
                  <Label required htmlFor={"password-input"}>
                    Password input
                  </Label>
                  <Input required type="password" id={"password-input"} />
                </DialogContent>
              </DialogBody>
            </form>
            <Row>
              <Col xs={9}>
                <Checkbox labelPosition="before" label="Same Organization?" />
              </Col>

              <Col xs={2}>
                <Button
                  shape="circular"
                  size="small"
                  style={{
                    marginLeft: "-23px",
                  }}
                >
                  Invite
                </Button>
              </Col>
            </Row>
          </DialogSurface>
        </Dialog>
      </div>
    </>
  );
};

export default FrontPage;
