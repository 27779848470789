import React from "react";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@fluentui/react-components";
import { PopoverSurface } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import CardComponent from "../Card";

import { DRAFT_CLAUSE_PROMPT } from "../../constant";
const DraftQuestion = () => {
  const [textFieldValue, setTextFieldValue] = useState("");

  const navigate = useNavigate();
  // Determine if the button should be disabled
  const isButtonDisabled = textFieldValue.trim() === "";

  // Set button class based on whether it is disabled
  const buttonClass = isButtonDisabled ? "" : "gradient_color";

  const homePage = () => {
    navigate("/frontpage");
  };

  const Draft = () => {
    navigate("/Draft", { state: { content: textFieldValue, direction: "draft" } });
  };
  const handleCardClick = (value) => {
    setTextFieldValue(value);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };
  return (
    <div>
      <Container>
        <Row
          style={{
            borderBottom: "1px solid black",
            paddingBottom: "5px",
            borderColor: "#CCCCCC",
          }}
        >
          <PopoverSurface
            tabIndex={-1}
            style={{
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <Row
              style={{
                borderBottom: "1px solid black",
                paddingBottom: "5px",
                borderColor: "#CCCCCC",
              }}
            >
              <Col className="mt-2">
                <span>
                  <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
                </span>
              </Col>
              <Col>
                <div
                  className="font-sans fw-semibold fs-6 text-muted"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  Draft
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Box component="form" noValidate autoComplete="off">
                <textarea
                  className="reviseTextarea"
                  id="standard-multiline-static"
                  rows={6}
                  placeholder="Be specific and verbose. The more detail you provide, the better the results."
                  style={{
                    marginTop: "9px",
                    borderRadius: "6px",
                    width: "100%",
                    borderColor: "#80808038",
                  }}
                  value={textFieldValue}
                  onChange={handleTextFieldChange}
                />
              </Box>
              <Row className="d-flex justify-content-center flex-row">
                <p className="mt-3 fs-6 fw-medium">Try Asking</p>
                <Col>
                  {DRAFT_CLAUSE_PROMPT.map((card, index) => (
                    <CardComponent key={index} card={card} handleCardClick={handleCardClick} />
                  ))}
                </Col>
              </Row>
              <Col xs={10}></Col>
              <Col className="d-flex justify-content-end">
                <Button
                  shape="rounded"
                  size="medium"
                  className={`mt-3 ${buttonClass}`} // Apply class based on condition
                  disabled={isButtonDisabled} // Disable button based on condition
                  onClick={Draft}
                >
                  Draft
                </Button>
              </Col>
            </Row>
          </PopoverSurface>

          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default DraftQuestion;
