import { getDocumentContent } from "../../../office-document";

export async function sendRequest(content, direction) {
  const url = "https://www.lawgic.sg/waitlist/api/web/contract/realtime/answer";
  const options = {
    method: "POST",
    headers: {
      Authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7Imxhc3ROYW1lIjoibCIsInJvbGUiOiJDb3Jwb3JhdGUiLCJ0ZWxlcGhvbmUiOiIrODYgMTM1ODU5NTI2NjQiLCJpbmR1c3RyeSI6Ik90aGVycyIsInVwZGF0ZVRpbWUiOjE3MTkwNTgxNzAwMDAsInRpdGxlIjoicGFydG5lciIsImZpcnN0TmFtZSI6InNkIiwiY3JlYXRlVGltZSI6MTcxOTA1ODE3MDAwMCwibmlja25hbWUiOiJzbCIsImNvbXBhbnkiOiJsYXdnaWMiLCJpZCI6MTgwNDQ4Njg3MDE4OTYwODk2MCwiZW1haWwiOiJsc2RlZUAxNjMuY29tIiwidXNlcm5hbWUiOiIxODA0NDg2ODcwMTg5NjA4OTYwIn0sImV4cCI6MTcyNjI0ODc5NywianRpIjoiMTgwNDQ4Njg3MDE4OTYwODk2MCJ9.NwIQIkKp8pkKxDPZ-QJ3LAB_WJ_-tGHAoeVro1vJn7w",

      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      stance: "",
      direction: direction,
      contractContent: await getDocumentContent(),
      chatRecords: [
        {
          role: "user",
          type: "question",
          content: content,
          createTime: new Date().toISOString(),
          fileInfo: "test",
        },
      ],
    }),
  };

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
