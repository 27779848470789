import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../assets/Main.css";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="m-5 mt-5 d-flex flex-column">
        <h3>Welcome to Lawgic</h3>
        <p className="opacity-75">Lawgic helps legal professionals and SMEs draft and review contracts 5x faster.</p>
        <p className="opacity-75">Lawgic can:</p>
        <ol className="opacity-75 list-unstyled">
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Help you understand contracts
          </li>
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Suggest improvements{" "}
          </li>
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Draft new clauses
          </li>
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Modify based on instructions
          </li>
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Negotiate terms
          </li>
          <li>
            <span>
              <img src="assets/Frame.png" alt="" />
            </span>
            Translate into multi-language{" "}
          </li>
        </ol>
      </div>
      <div className="d-flex justify-content-center">
        <Button size="large" shape="circular" className="btnn" onClick={() => navigate("/frontpage")}>
          <span>
            <img
              src="assets/Frame_btn.png"
              alt=""
              style={{
                width: "27px",
                height: "27px",
                marginRight: "2px",
              }}
            />
          </span>
          Get Started
        </Button>
      </div>
    </>
  );
};

export default Index;
