import React, { useState, useEffect } from "react";
import { MessageBox, Input } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import "./chat.css";
import { useNavigate } from "react-router-dom";
import ChatPrompts from "./chatPrompts";
import { sendRequest } from "../../../Services";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { ArrowLeftRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
const AskChat = () => {
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);

  const [inputText, setInputText] = useState("");
  const [responseLoading, setResponseLoading] = useState(false);

  const location = useLocation();
  const { Text } = location.state;

  useEffect(() => {
    if (Text) {
      handleSendMessage(Text);
    }
  }, [Text]);
  const homePage = () => {
    navigate("/frontpage");
  };
  // Handle sending a message
  const handleSendMessage = (messageText) => {
    // Send the user's message
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        position: "right",
        type: "text",
        text: messageText,
      },
    ]);

    // Clear the input
    setInputText("");

    // Show the loader after sending the message
    setResponseLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        position: "left",
        type: "custom",
        text: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              width={32}
              height={32}
              src="https://www.lawgic.sg/review/assets/Loader-CZbZ12IH.gif"
              alt="loading..."
              style={{ marginRight: "10px" }}
            />
            <span>Thinking</span>
          </div>
        ),
      },
    ]);

    // Send the request to the API
    sendRequest(messageText, "ask")
      .then((result) => {
        console.log(result); // Log the response to the console

        setResponseLoading(false);

        // Remove the loader and show the API response
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // Remove the loader
          {
            position: "left",
            type: "text",
            text: result.data.content || "No response from API",
          },
        ]);
      })
      .catch((error) => {
        console.error(error);

        setResponseLoading(false);

        // Remove the loader and show an error message
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1), // Remove the loader
          {
            position: "left",
            type: "text",
            text: "Error: Could not retrieve response",
          },
        ]);
      });
  };

  // Handle card click
  const handleCardClick = (text) => {
    // Set the text to input and send the message
    setInputText(text);
    handleSendMessage(text);
  };

  return (
    <>
      <Row
        style={{
          borderBottom: "1px solid black",
          paddingBottom: "5px",
          borderColor: "#CCCCCC",
          width: "99%",
        }}
      >
        <Col xs={3} className="mt-2">
          <span>
            <Tooltip title="Home" placement="top">
              <span>
                <Button size="medium" appearance="transparent" icon={<ArrowLeftRegular />} onClick={homePage} />
              </span>
            </Tooltip>
          </span>
        </Col>

        <Col xs={5} className="d-flex justify-content-center">
          <div
            className="font-sans fw-semibold fs-6 text-muted"
            style={{
              marginTop: "10px",
              marginBottom: "8px",
            }}
          >
            Ask
          </div>
        </Col>
        <Col></Col>
      </Row>

      <div>
        <MessageBox
          position="left"
          type="text"
          text="Hello, I'm LawSea, your legal AI Assistant！
"
        />
        <ChatPrompts onCardClick={handleCardClick} loading={responseLoading} />
        <div
          style={{
            paddingBottom: "80px",
          }}
        >
          {messages.map((message, index) => (
            <MessageBox
              key={index}
              position={message.position}
              type={message.type === "custom" ? "text" : message.type}
              text={message.text}
            />
          ))}
          <div className="input_container">
            <Input
              placeholder="Type here..."
              multiline={false}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              rightButtons={
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    if (inputText.trim()) {
                      handleSendMessage(inputText);
                    }
                  }}
                  style={{
                    cursor: inputText.trim() ? "pointer" : "not-allowed",
                    opacity: inputText.trim() ? 1 : 0.5,
                  }}
                >
                  <path
                    d="M18.3788 10.3095C18.191 10.7122 17.8711 11.0314 17.4879 11.2235L3.00043 18.5353C1.97265 19.0378 0.732382 18.628 0.210757 17.6213C-0.018837 17.1351 -0.0654074 16.5864 0.0935458 16.0746L1.67797 10.9167C1.82305 10.4444 2.25929 10.122 2.75337 10.122H8.29845C8.70603 10.1204 9.03931 9.7911 9.04862 9.3721C9.04604 8.9596 8.71686 8.62618 8.29845 8.62217H2.75745C2.26142 8.62217 1.82394 8.29731 1.6805 7.82246L0.116983 2.64617C-0.210134 1.56659 0.407374 0.415133 1.5001 0.0917194C2.00588 -0.0672806 2.55303 -0.0190228 3.02387 0.232344L17.4879 7.54414C18.4983 8.06021 18.905 9.30198 18.3788 10.3095Z"
                    fill={inputText.trim() ? "#999999" : "#cccccc"}
                  ></path>
                </svg>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AskChat;
