// components/CardComponent.js
import React from "react";
import { Card } from "@fluentui/react-components";

const CardComponent = ({ card, handleCardClick }) => {
  return (
    <Card
      style={{
        width: "109%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #F0F0F0",
        height: "auto",
        borderRadius: "8.5px",
        minHeight: "55px",
        gap: "0",
      }}
      onClick={() => handleCardClick(card.value)}
      className="p-2 text-center mb-2 reviseCard"
    >
      <div>{card.key}</div>
      <div>{card.content}</div>
    </Card>
  );
};

export default CardComponent;
