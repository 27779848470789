// AES encryption function
export const encryptAES = async (params) => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));

  if (type === "Base64") {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      if (ele === "password") {
        // Encrypt only the password
        let dataToEncrypt = result[ele];
        key = CryptoJS.enc.Latin1.parse(key);
        const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });
        result[ele] = encrypted.toString();
      }
    });
  }

  return result;
};
