import * as React from "react";
import { Button, Tooltip } from "@fluentui/react-components";
import Card from "react-bootstrap/Card";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";

const ReviewCard = () => {
  return (
    <div
    // style={{
    //   maxhHeight: "1000px",
    //   height: "300px",
    //   overflow: "auto",
    // }}
    >
      <Card style={{ width: "18rem", marginLeft: "8px", marginTop: "12px" }}>
        <Card.Body>
          <h6
            style={{
              marginBottom: "11px",
            }}
          >
            Address Clarification
          </h6>
          <Card.Text className="text-muted">
            Max Ye's address should be filled in here to ensure that there is no ambiguity regarding his identity.
          </Card.Text>
          <Button size="small" className="review_card_btn me-2 py-2">
            Add Comment
          </Button>
          <Tooltip content="Show target text in document" relationship="label" appearance="inverted">
            <Button
              icon={
                <NearMeOutlinedIcon
                  style={{
                    transform: "rotate(270deg)",
                  }}
                />
              }
              size="large"
              appearance="transparent"
              style={{
                color: "#458CFF",
                backgroundColor: "#E9F0FE",
                padding: "3px",
                borderRadius: "7px",
              }}
            />
          </Tooltip>
          <Button
            size="small"
            className="py-2"
            style={{
              float: "right",
            }}
          >
            Dismiss
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ width: "18rem", marginLeft: "8px", marginTop: "12px" }}>
        <Card.Body>
          <h6
            style={{
              marginBottom: "11px",
            }}
          >
            Typographical Correction
          </h6>
          <Card.Text className="text-muted">
            The term"Companylndividual" seems to be a typographical error. Since we are referring to Max Ye an
            individual the term should be corrected to "Individua".
          </Card.Text>
          <div className="correction_card">
            <span>
              <del>Company</del>Individual
            </span>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReviewCard;
