import React, { useEffect, useState } from "react";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter, faTrashCan, faICursor } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from "react-bootstrap";
import "./Draft.css";
import { LuCopyPlus } from "react-icons/lu";
import { TbCopyCheck } from "react-icons/tb";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./Pagination.css";
import { useNavigate, useLocation } from "react-router-dom";
import { sendRequest } from "../../Services";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container } from "react-bootstrap";
import AssistantMenu from "../MainScreen/AssistantMenu";
import Loader from "../Loader";
import { insertTextAtCursor } from "../../../../office-document";

function Draft() {
  const navigate = useNavigate();
  const location = useLocation();
  const { content, direction } = location.state;

  const [currentPage, setCurrentPage] = useState(1);
  const [responseLoading, setResponseLoading] = useState(false);
  const [responseContent, setResponseContent] = useState("");
  const [copyIcon, setCopyIcon] = useState(<LuCopyPlus size={22} style={{ paddingBottom: "5px" }} />);

  useEffect(() => {
    setResponseLoading(true);
    sendRequest(content, direction)
      .then((result) => {
        console.log(result);
        setResponseLoading(false);
        if (result.code === "200") {
          setResponseContent(result.data.content);
        } else {
          setResponseContent("");
        }
      })
      .catch((error) => {
        setResponseLoading(false);

        console.error(error);
      });
  }, []);

  const homePage = () => {
    navigate("/frontpage");
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(responseContent)
      .then(() => {
        setCopyIcon(<TbCopyCheck size={22} style={{ paddingBottom: "5px", color: "green" }} />);
        setTimeout(() => {
          setCopyIcon(<LuCopyPlus size={22} style={{ paddingBottom: "5px" }} />);
        }, 2000); // Reset icon after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const handleDismiss = () => {
    setResponseContent("");
  };

  const generatePageContent = (page) => {
    // Dynamic content generation logic
  };
  const insertAtCursor = () => {
    insertTextAtCursor(responseContent);
  };

  const pageContent = generatePageContent(currentPage);

  return (
    <>
      <Container>
        <AssistantMenu />
        <div className="row" style={{ borderBottom: "1px solid grey" }}>
          <div className="col-2 mt-2">
            <Tooltip title="Home" placement="top">
              <HomeOutlinedIcon
                sx={{ fontSize: 27 }}
                style={{ color: "black", opacity: 0.4, cursor: "pointer" }}
                onClick={homePage}
              />
            </Tooltip>
          </div>
          <div className="col-2 mt-2">
            <AddCircleOutlineOutlinedIcon
              sx={{ fontSize: 22 }}
              style={{ marginBottom: "3px", color: "black", opacity: 0.4, cursor: "pointer" }}
              className="plus_icon"
            />
          </div>
          <div className="col-1"></div>
          <div className="col-2 mt-2">
            <p className="mt-1" style={{ fontWeight: 600, fontSize: "16px", opacity: 0.5 }}>
              Draft
            </p>
          </div>
        </div>

        <div className="wrapper">
          <div className="wrapper_upper">
            <Box component="form" noValidate autoComplete="off" style={{ marginLeft: "7px", width: "90%" }}>
              <TextField
                id="standard-multiline-static"
                multiline
                rows={3}
                value={content}
                variant="standard"
                placeholder="Confidentiality agreement provisions to protect trade secrets"
                style={{ width: "250px" }}
              />
            </Box>
            <div className="wrapper_buttons">
              <Button href="#" variant="outline-secondary">
                Clear
              </Button>
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} id="UpRightAndDownLeftArrow" />
            </div>
          </div>

          {responseLoading ? (
            <div>
              <div className="gradient_color" style={{ height: "7px", width: "100%", marginTop: "6px" }}></div>
              <div>
                <p className="fw-semibold fs-6 mt-2">Loading</p>
              </div>
              <div className="d-flex">
                <Loader />
                <span className="fw-semibold mt-1">Still working</span>
              </div>
            </div>
          ) : (
            <div className="wrapper_hero">
              <Card style={{ width: "95%" }} className="hero_card">
                <p key={currentPage} className="text-muted fw-normal p-2">
                  {responseContent}
                </p>
                <div className="wrapper_hero_buttons">
                  <div className="trash">
                    <Tooltip title="Dismiss" placement="top">
                      <Button
                        onClick={handleDismiss}
                        variant="outline-light text-muted"
                        style={{ border: "1px solid #ccc" }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} style={{ paddingBottom: "5px" }} />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="copy">
                    <Tooltip title="Copy" placement="top">
                      <Button
                        onClick={handleCopy}
                        variant="outline-light text-muted"
                        style={{ border: "1px solid #ccc" }}
                      >
                        {copyIcon}
                      </Button>
                    </Tooltip>
                    <Button className="review_card_btn" onClick={insertAtCursor}>
                      <FontAwesomeIcon
                        icon={faICursor}
                        style={{ fontSize: "13px", paddingBottom: "3px", paddingRight: "5px" }}
                      />
                      Insert at cursor
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default Draft;
