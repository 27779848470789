import React, { useState } from "react";
import "../../../../../assets/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { NavigationRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { useId, Divider } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { List, ListItem, useListSelection } from "@fluentui/react-migration-v0-v9";
import PropTypes from "prop-types";

const items = [
  {
    content: "LAWGIC PRIVATE LIMITED",
  },
  {
    content: "[Individual's Full Legal Name]",
  },
  {
    content: "Max Ye",
  },
];

const AssistantMenu = ({ openPopupAssitant }) => {
  const [popoverVisibility, setpopoverVisibility] = useState(true);
  const [popoverTeam, setPopoverTeam] = useState(true);
  const [popoverInput, setpopoverInput] = useState(false);

  const selection = useListSelection({ selectionMode: "single" });
  const id = useId();

  const togglePopover = () => {
    setPopoverTeam(false);
    setpopoverInput(true);
  };

  const toggleInput = () => {
    setPopoverTeam(true);
    setpopoverInput(false);
  };

  const handleInvite = () => {
    openPopupAssitant(true);
  };

  const handlePopover = () => {
    setpopoverVisibility(!popoverVisibility);
  };

  const input_style = {
    padding: "3px",
    width: "90%",
    border: "none",
    outline: "none",
  };
  const search_box = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px",
  };
  return (
    <>
      <Row
        style={{
          borderBottom: "1px solid black",
          paddingBottom: "5px",
          borderColor: "#CCCCCC",
        }}
      >
        <Popover trapFocus>
          <PopoverTrigger disableButtonEnhancement>
            <Col className="mt-2">
              <span>
                <Button size="medium" appearance="transparent" icon={<NavigationRegular />}></Button>
              </span>
            </Col>
          </PopoverTrigger>
          {popoverVisibility && (
            <PopoverSurface
              style={{
                height: "200px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                width: "100%",
              }}
            >
              <div>
                <>
                  <Row
                    style={{
                      borderBottom: "1px solid black",
                      paddingBottom: "5px",
                      borderColor: "#CCCCCC",
                    }}
                  >
                    <Col className="mt-2">
                      <span>
                        <Button
                          size="medium"
                          appearance="transparent"
                          icon={<DismissRegular />}
                          onClick={handlePopover}
                        />
                      </span>
                    </Col>
                    <Col>
                      <div
                        className="font-sans fw-semibold fs-6 text-muted"
                        style={{
                          marginTop: "12px",
                        }}
                      >
                        Setting
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>

                  {/* PopOver input with cancel button */}
                  {popoverInput && (
                    <Row>
                      <Col
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <div style={search_box}>
                          <input
                            type="text"
                            // Calling event of search value

                            placeholder="Enter Party Name"
                            style={input_style}
                          />
                          <div className="input-group-append">
                            <Button shape="rounded" size="medium" onClick={toggleInput}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {popoverTeam && (
                    <Row>
                      <Col className="mt-3">
                        <Popover trapFocus size="large">
                          <PopoverTrigger disab leButtonEnhancement>
                            <Button
                              appearance="outline"
                              className="dropdown text-muted"
                              size="medium"
                              style={{
                                justifyContent: "flex-start",
                                borderRadius: "14px",
                                marginLeft: "7px",
                              }}
                            >
                              <GroupsOutlinedIcon
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              Select a Party
                              <ExpandMoreOutlinedIcon
                                style={{
                                  marginLeft: "95px",
                                }}
                              />
                            </Button>
                          </PopoverTrigger>
                          <PopoverSurface
                            aria-labelledby={id}
                            className="mt-3"
                            style={{
                              width: "85%",
                              borderRadius: "20px",
                              fontSize: "19px",
                              height: "195px",
                              paddingTop: "2px",
                            }}
                          >
                            <div>
                              <List
                                truncateContent
                                truncateHeader
                                selectable
                                defaultSelectedItems={[]}
                                selectedItems={selection.selectedItems}
                                onSelectionChange={(_, data) => selection.setSelectedItems(data.selectedItems)}
                              >
                                {items.map(({ key, content }) => (
                                  <ListItem key={key} value={key} className="list_item">
                                    {content}
                                  </ListItem>
                                ))}
                                <Divider inset className="mb-2" />

                                <Button icon={<AddRegular />} appearance="transparent" onClick={togglePopover}>
                                  Add New Party
                                </Button>
                              </List>
                            </div>
                          </PopoverSurface>
                        </Popover>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <div className="d-flex justify-content-around pt-4">
                      <span>
                        <PersonAddAltIcon
                          style={{
                            width: "21px",
                            marginLeft: "8px",
                          }}
                        />
                      </span>
                      <a onClick={handleInvite}>
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                          }}
                        >
                          Invite someone to Lawgic
                        </span>
                      </a>
                      <span>3</span>
                    </div>
                  </Row>
                </>
              </div>
            </PopoverSurface>
          )}
        </Popover>

        <Col>
          <div
            className="font-sans fw-semibold fs-6"
            style={{
              marginTop: "12px",
            }}
          >
            Assistant
          </div>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

AssistantMenu.propTypes = {
  openPopupAssitant: PropTypes.func.isRequired,
};

export default AssistantMenu;
