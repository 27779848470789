import { encryptAES } from "../../Utilities/Encryption";
// Function to get encryption factor
export async function getEncryptedFactor() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch("https://www.lawgic.sg/waitlist/api/web/portal/get/encrypt/factor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        reject(`Error: ${response.status} - ${response.statusText}`);
        return;
      }

      const setCookieHeader = response.headers.get("Set-Cookie");
      console.log("Set-Cookie Header:", setCookieHeader);
      console.log("Document Cookies:", document.cookie);

      // Extract the cookie value from the Set-Cookie header
      const cookieValue = setCookieHeader ? setCookieHeader.split(";")[0] : "";

      const result = await response.json();
      resolve(result);
    } catch (error) {
      reject(`Network Error: ${error.message}`);
    }
  });
}

// Modified login function to include encryption
export async function login(email, password) {
  return new Promise(async (resolve, reject) => {
    try {
      // Step 1: Get the encryption factor (key)
      const encryptionKey = await getEncryptedFactor();
      console.log("Encryption Key:", encryptionKey);

      // Step 2: Encrypt only the password using the encryption key
      let params = {
        data: { email: email, password: password },
        type: "AES",
        param: ["password"],
        key: encryptionKey.data, // Use the factor as the encryption key
      };
      const encryptedData = await encryptAES(params);

      console.log("Encrypted Password:", encryptedData.password);

      // Step 3: Make the login request with the unencrypted email and encrypted password
      const response = await fetch("https://www.lawgic.sg/waitlist/api/web/portal/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",

        body: JSON.stringify(encryptedData),
      });

      if (!response.ok) {
        reject(`Error: ${response.status} ${response.statusText}`);
        return;
      }

      const authHeader = response.headers.get("Authorization");
      const data = await response.json();

      // Step 4: Store the Authorization token in localStorage
      window.localStorage.setItem("lawgic_accessToken", authHeader);

      // Resolve the promise with the response data and authHeader
      resolve({ data, authHeader });
    } catch (error) {
      reject(`Network Error: ${error.message}`);
    }
  });
}
