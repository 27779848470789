import * as React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";

import { faCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssistantMenu from "../MainScreen/AssistantMenu";
import { useNavigate } from "react-router-dom";

const NegotiateScreen = () => {
  const navigate = useNavigate();
  const homePage = () => {
    navigate("/frontpage");
  };

  const input_style = {
    padding: "3px",
    width: "90%",
    border: "none",
    outline: "none",
    fontWeight: "500",
  };
  const search_box = {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "13px",
    padding: "5px",
    width: "94%",
    marginLeft: "9px",
    height: "44px",
    position: "relative",
    bottom: "10px",
  };
  return (
    <div>
      <Container>
        <AssistantMenu />
        <div
          className="row"
          style={{
            borderBottom: "1px solid #cccc",
            height: "45px",
          }}
        >
          <div className="col-4 mt-2">
            <HomeOutlinedIcon
              sx={{ fontSize: 27 }}
              style={{ color: "black", opacity: 0.4, marginRight: "10px" }}
              onClick={homePage}
            />
            <AddCircleOutlineOutlinedIcon
              sx={{ fontSize: 22 }}
              style={{ marginBottom: "3px", color: "black", opacity: 0.4 }}
              className="plus_icon"
            />
          </div>
          <div className="col-1"></div>
          <div className="col-2 mt-2">
            <p className="mt-1" style={{ fontWeight: 600, fontSize: "16px", opacity: 0.5 }}>
              Lawgic
            </p>
          </div>
          <div className="col-3"></div>
          <div className="col-2"></div>
        </div>
        <div>
          <Row>
            <div className="main p-3">
              <h6>Points to Negotiate</h6>
              <div
                className="chat"
                style={{
                  backgroundColor: "#F6F6F6",
                  width: "98%",
                  borderRadius: "14px",
                }}
              >
                <p className="p-2">
                  <span className="fw-bold d-block">Term of Agreement</span>
                  Negotiate for a longer term. The longer the term of the agreement, the longer the confidential
                  information will be protected and the parties will have more time to explore the possibility of a
                  business relationship.
                  <br />
                  <span className="fw-bold d-block"> Notice of Unauthorised Use </span>
                  Specify the timeframe for notifying the Disclosing party of any unauthorized use or disclosure. Having
                  a specific timeframe for notification ensures prompt action can be taken to prevent further
                  unauthorized use or disclosure of confidential information.
                  <br />
                  <span className="fw-bold d-block"> Assignment</span>
                  Allow assignment of the agreement with prior written consent that is not unreasonably withheld or
                  delayed. Allowing assignment provides flexibility for the parties in case.
                </p>
              </div>
              <Row>
                <Col className="text-muted">
                  <span>
                    <ContentCopyIcon
                      style={{
                        width: "20px",
                      }}
                    />
                  </span>
                </Col>
              </Row>
            </div>
          </Row>

          <Row>
            <Col
              style={{
                marginTop: "15px",
              }}
            >
              <div style={search_box}>
                <input type="text" placeholder="Ask a follow up..." style={input_style} />
                <div className="input-group-append">
                  <FontAwesomeIcon icon={faCircleUp} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default NegotiateScreen;
