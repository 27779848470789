import React, { useState } from "react";
import "../../../../../assets/Main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { DismissRegular } from "@fluentui/react-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Cards from "../CardList/Cards";
import { Checkbox } from "@fluentui/react-components";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  Button,
  Input,
  Label,
  makeStyles,
  MessageBar,
  MessageBarBody,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    color: "#999999",
  },
});

const HomeScreen = () => {
  const [isNotify, setIsNotify] = useState(false);
  const [popUp, setPopUp] = useState(false);
  
  const openPopup = () => {
    setPopUp(true);
  };
  
  const styles = useStyles();
  const handleInviteClick = () => {
    setPopUp(false);
    setIsNotify(true);
    // Set a timeout to change isNotify to false after one second
    setTimeout(() => {
      setIsNotify(false);
    }, 1000);
  };

  return (
    <div>
      <Cards />

      {isNotify && (
        <div
          className="d-flex "
          style={{
            position: "fixed",
            zIndex: "23",
            width: "100%",
            top: "301px",
            left: "45px",
          }}
        >
          <MessageBar intent="success">
            <MessageBarBody>Thank you! Invitation sent.</MessageBarBody>
          </MessageBar>
        </div>
      )}

      <Cards />
      <Cards />
      <Button size="medium" shape="circular" className="btnnn w-75 d-flex justify-content-between" onClick={openPopup}>
        <span>
          <PersonAddAltIcon
            style={{
              width: "18px",
              marginTop: "3px",
              marginLeft: "4px",
            }}
          />
        </span>
        <span
          style={{
            fontWeight: "300",
            fontSize: "14px",
          }}
        >
          Invite someone to Lawgic
        </span>
        <a
          style={{
            color: "white",
            marginTop: "8px",
          }}
        >
          {<DismissRegular fontSize={16} />}
        </a>
      </Button>
      {popUp && (
        <DialogSurface aria-describedby={undefined}>
          <form>
            <DialogBody>
              <DialogTitle className="text-muted fs-6">Invite a colleague or friend to try Lawgic</DialogTitle>
              <DialogContent className={styles.content}>
                They will skip to the front of our 83000+ person waitlist.
                <InputGroup
                  style={{
                    width: "96%",
                    margin: "2px",
                  }}
                >
                  <Form.Control
                    placeholder="Their Name"
                    type="text"
                    aria-describedby="inputGroup-sizing-sm"
                    style={{
                      padding: "9px",
                    }}
                  />
                </InputGroup>
                <InputGroup
                  style={{
                    width: "96%",
                    margin: "2px",
                  }}
                >
                  <Form.Control
                    placeholder="Their Email"
                    type="email"
                    aria-describedby="inputGroup-sizing-sm"
                    style={{
                      padding: "9px",
                    }}
                  />
                </InputGroup>
              </DialogContent>
            </DialogBody>
          </form>
          <Row>
            <Col xs={9}>
              <Checkbox
                labelPosition="before"
                label="Same Organization?"
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              />
            </Col>

            <Col xs={2}>
              <Button
                onClick={handleInviteClick}
                shape="circular"
                size="small"
                style={{
                  marginLeft: "-23px",
                  marginTop: "5px",
                }}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </DialogSurface>
      )}
      <Dialog modalType="non-modal">
        <DialogTrigger disableButtonEnhancement></DialogTrigger>
        <DialogSurface aria-describedby={undefined}>
          <form>
            <DialogBody>
              <DialogTitle className="text-muted">Invite a colleague or friend to try Lawgic</DialogTitle>
              <DialogContent className={styles.content}>
                They will skip to the front of our 83000+ person waitlist.
                <Label required htmlFor={"email-input"}>
                  Email input
                </Label>
                <Input required type="email" id={"email-input"} />
                <Label required htmlFor={"password-input"}>
                  Password input
                </Label>
                <Input required type="password" id={"password-input"} />
              </DialogContent>
            </DialogBody>
          </form>
          <Row>
            <Col xs={9}>
              <Checkbox labelPosition="before" label="Same Organization?" />
            </Col>

            <Col xs={2}>
              <Button
                shape="circular"
                size="small"
                style={{
                  marginLeft: "-23px",
                }}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default HomeScreen;
